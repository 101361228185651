import { invokeByModel } from '@wix/ambassador-data-science-ml-framework-v1-feature-vector/http';
import { HttpClient } from '@wix/http-client';
import type { HasImageTextByDataScienceParams } from './hasImageTextByDataScience.types';

export const hasImageTextByDataScience = async ({
  base64Image,
  imageId,
  imageUrl,
  metaSiteId,
  siteId,
}: HasImageTextByDataScienceParams): Promise<boolean> => {
  const httpClient = new HttpClient();

  const { data } = await httpClient.request(
    invokeByModel({
      features: {
        values: {
          encoded_image: {
            strVal: base64Image,
          },
          image_id: {
            strVal: imageId,
          },
          image_url: {
            strVal: imageUrl,
          },
          meta_site_id: {
            strVal: metaSiteId,
          },
          site_id: {
            strVal: siteId,
          },
        },
      },
      modelId: 'ds-text-detection-0-1',
      triggeredBy: 'testing',
    }),
  );

  if (data?.output?.values?.result?.strVal) {
    return JSON.parse(data.output.values.result.strVal).has_text;
  }

  return false;
};
